<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\triprequests\\edit.6691') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationObserver ref="validator" slim>
                <template v-if="tripRequest.trip">
                    <v-select
                        v-model="tripRequest.trip"
                        :label="$t('src\\views\\triprequests\\edit.8773')"
                        :items="trips"
                        item-text="name"
                        item-value="_id"
                        outlined
                    />
                </template>
                <template v-else>
                    <ValidationProvider rules="required">
                        <v-select
                            :value="tripRequest.direction === 'other' ? tripRequest.directionOther : tripRequest.direction"
                            :label="$t('src\\views\\triprequests\\edit.788')"
                            :items="formattedDirections"
                            item-text="title"
                            item-value="code"
                            outlined
                            @change="setDirection"
                        />
                    </ValidationProvider>
                    <ValidationProvider rules="required">
                        <v-select
                            :value="tripRequest.directionAdditional === 'other' ? tripRequest.directionAdditionalOther : tripRequest.directionAdditional"
                            :label="$t('src\\views\\triprequests\\edit.8602')"
                            :items="formattedDirectionsAdditional"
                            item-text="title"
                            item-value="code"
                            outlined
                            @change="setDirectionAdditional"
                        />
                    </ValidationProvider>
                </template>
                <br />
                <ValidationProvider rules="required">
                    <h4>{{ $t('src\\views\\triprequests\\edit.6379') }}</h4>
                    <v-radio-group
                        v-model="tripRequest.duration">
                        <v-row>
                            <template v-for="(item, index) in formattedDurations">
                                <v-col
                                    :key="index"
                                    cols="12"
                                    md="3">
                                    <v-radio
                                        :label="item.title"
                                        :value="item.code"
                                    />
                                </v-col>
                            </template>
                        </v-row>
                    </v-radio-group>
                </ValidationProvider>
                <ValidationProvider rules="required">
                    <v-text-field
                        v-model="tripRequest.start"
                        :label="$t('src\\views\\triprequests\\edit.1706')"
                        outlined
                    />
                </ValidationProvider>
                <br /><br />
                <ValidationProvider rules="required">
                    <h4>{{ $t('src\\views\\triprequests\\edit.7612') }}</h4>
                    <v-radio-group
                        v-model="tripRequest.comfort">
                        <v-row>
                            <template v-for="(item, index) in formattedComforts">
                                <v-col
                                    :key="index"
                                    cols="12"
                                    md="3">
                                    <v-radio
                                        :label="item.title"
                                        :value="item.code"
                                    />
                                </v-col>
                            </template>
                        </v-row>
                    </v-radio-group>
                </ValidationProvider>
                <br /><br />
                <ValidationProvider>
                    <h4>{{ $t('src\\views\\triprequests\\edit.8625') }}</h4>
                    <v-row class="my-0">
                        <template v-for="(item, index) in formattedActivities">
                            <v-col
                                :key="index"
                                cols="12"
                                md="3">
                                <v-checkbox
                                    :key="index"
                                    v-model="tripRequest.activities"
                                    :label="item.title"
                                    :value="item.code"
                                    hide-details
                                    class="ma-0"
                                />
                            </v-col>
                        </template>
                    </v-row>
                </ValidationProvider>
                <br /><br />
                <v-textarea 
                    v-model="tripRequest.workshops"
                    :placeholder="$t('src\\views\\triprequests\\edit.7461')"
                    outlined
                />
            </ValidationObserver>

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\triprequests\\edit.1732') }}</v-btn>
                <v-btn
                    v-if="tripRequest._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\triprequests\\edit.1967') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, translit } from '../../helpers';
    import { DIRECTION_TYPE, DIRECTION_TYPE_TITLE } from '../../vars';

    export default {
        name: 'TripRequestsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            DIRECTION_TYPE,
            DIRECTION_TYPE_TITLE,
            imageSrc,
            valid: true,
        }),
        computed: {
            ...mapState('quizes', ['dictionaries']),
            ...mapState('tripRequests', {
                tripRequest: state => state.entity
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            formattedDirections() {
                return this.dictionaries.directions.map(item => ({
                    ...item,
                    code: item.code.toString()
                }))
            },
            formattedDirectionsAdditional() {
                return this.dictionaries.directionsAdditional.map(item => ({
                    ...item,
                    code: item.code.toString()
                }))
            },
            formattedDurations() {
                return this.dictionaries.durations.map(item => ({
                    ...item,
                    code: item.code.toString()
                }))
            },
            formattedComforts() {
                return this.dictionaries.comforts.map(item => ({
                    ...item,
                    code: item.code.toString()
                }))
            },
            formattedActivities() {
                return this.dictionaries.activities.map(item => ({
                    ...item,
                    code: item.code.toString()
                }))
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('tripRequests/get', { id: this.$route.params.id });
            } else {
                await store.commit('tripRequests/CLEAR_ENTITY');
            }
            await store.dispatch('quizes/dictionaries');
            await store.dispatch('trips/fetch', { itemsPerPage: 100000 });
        },
        methods: {
            setDirection(value) {
                this.tripRequest.direction = value;
            },
            setDirectionAdditional(value) {
                this.tripRequest.directionAdditional = value;
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('tripRequests/SET_ENTITY', this.tripRequest);
                    const response = await store.dispatch('tripRequests/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'trip-requests-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('tripRequests/delete', { id: this.tripRequest._id });
                await this.$router.push({ name: 'trip-requests-list' });
            }
        }
    }
</script>
